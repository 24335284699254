import React from 'react'
import prodImage from "../../assets/brine_chiller.jpg"
export const AdiabiaticChiller = () => {
  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div>
            <div className="text-2xl font-bold my-4 text-left">
                <span>Adiabatic Evaporator, Forced Evaporative, Electrical Brine Chiller</span>
                <div className="text-right flex flex-col items-start mt-2">
                    <span className="block w-24 h-0.5 bg-green-600"></span>
                    <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
                </div>
            </div>
                <div className='font-bold mt-4 md:leading-relaxed text-gray-800 uppercase'>
                    Adiabatic Evaporator
                </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                    Adiabatic crystallization, simply put, is a chemical solid-liquid process wherein the mass transfer of a solute from the liquid solution occurs. While you’ll find many adiabatic crystallizer manufacturers to buy the crystallizer from, choosing the one that fits your business and budget requirements is crucial. 
                    <br />
                    <br />
                    As one of the trusted adiabatic crystallizer suppliers in India, we offer an evaporator that best suits your needs. While crystalization is basically used to separate solid from liquid, knowing your specific requirements will help our team suggest the type of adiabatic crystallizer to go for.
                </div>

                <div className='font-bold mt-6 md:leading-relaxed text-gray-800 uppercase'>
                    Electrical Brine Chiller
                </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                    These brine chillers are having wide applications in electroplating plants, anodizing plants, chemical process plants, Latex cooling, laboratory application, etc. The system will offer brine or water up to -30⁰C for cooling purposes by using reciprocating or screw-type compressors.
                <br />
                <br />
                    Brine Chiller is the best solution when the cooling medium is water along with glycol. It will only occur when the low fluid temperature is needed and even the glycol needs to be added for the cooling water to protect it from freezing. Our system will offer a constant supply of chilled propylene glycol from the range -25⁰C or +7⁰C. Ethylene glycol can only be used for such applications when the glycol circuits and not in the contact with the food products.
                </div>
            </div>
            <div className='mt-4 text-center flex justify-center '>
                <img src={prodImage} alt='' className='w-3/4 border-2 shadow-md rounded'/>
            </div>
        </div>
    </div>
  )
}
