import React from 'react'
import poster from "../assets/slider-2.png"
export const IntroVideo = () => {
  return (
    <div className='bg-gray-100 p-4 py-10 md:px-6 xl:px-40'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 items-center">
            <div>
                <div className="text-xl font-semibold">
                    <span>About Us</span>
                </div>
                <div className='text-2xl md:text-3xl font-bold mt-4 md:leading-relaxed text-gray-800'>
                    Explore how our dedication <br /> to sustainability <br /> shapes every step of our <br />manufacturing process.
                </div>
            </div>
            <div className='mt-4'>
                <video src="" className='w-full' poster={poster}></video>
            </div>
        </div>
    </div>
  )
}
