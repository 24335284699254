import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "../assets/Slider-1.jpg";
import slider2 from "../assets/Slider-2.jpg";
import slider3 from "../assets/Slider-3.jpg";
import slider4 from "../assets/slider-4.jpg";
import slider5 from "../assets/Slider-5.jpg";
// import slider5 from "../assets/slider-11.jpeg";

export const HomeSlider = () => {

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none",justifyContent:"center",alignItems:"center",background: "gray",opacity:"0.7", borderRadius:'50%',width:"50px",height:"50px",zIndex:"9999" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none",justifyContent:"center",alignItems:"center",background: "gray",opacity:"0.7",borderRadius:'50%',width:"50px",height:"50px",zIndex:"9999" }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    dots: false,
    fade:true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
const fadeImages = [
    {
      url: slider1,
      productName: 'Falling Film Evaporator, Rising Film Eavaporator',
      desc:'A falling film evaporator is a type of heat exchanger used to evaporate a liquid, typically in the process industries. It operates by distributing the liquid as a thin film down the surface of a heated tube or plate.'
    },
    {
      url: slider2,
      productName: 'Forced Circulation, Falling with Forced circulation',
      desc:'Uses a pump to circulate the liquid through the heat exchanger tubes at high velocities.'
    },
    {
      url: slider3,
      productName: 'MVR Evaporator',
      desc:'Advanced and energy-efficient type of evaporator that recycles energy from the vapor generated during the evaporation process.'
    },
    {
        url: slider4,
        productName: 'Adiabatic Evaporator, Forced Evaporative, Electrical Brine Chiller',
        desc:'Cooling system that uses the principle of adiabatic cooling, where air passes through a wetted medium and is cooled through the evaporation of water.'
    },
    {
        url: slider5,
        productName: 'Agitated Dryer, Flash Dryer',
        desc:'Dryer that uses mechanical agitation to enhance the drying process.'
    },
  ];

  return (
    <div className="slider md:mt-7 bg-blue-800">
            <Slider {...settings}>
                {fadeImages.map((fadeImage, index) => (
                    <div key={index} className='w-40'>
                        <img className="w-full h-auto" alt='Slider Images' src={fadeImage.url} />
                    </div>
                ))}
            </Slider>
    </div>
  )
}
