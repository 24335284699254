import React, { useState} from 'react'
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Logo from '../assets/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { HomeSlider } from './HomeSlider';
import { Link,useLocation } from 'react-router-dom';

const Header = () => {
    
    const [navExpand,setNavExpand]=useState(false);
    const [showList,setList]=useState(false);
    const location = useLocation(); 


  return (
    <div>
        <div className="header bg-blue-800 p-2 px-4 md:px-10 xl:px-40 text-white text-sm md:text-lg" id="home">
            <div className="flex items-center justify-between">
                <div className="contact flex items-center gap-2 font-semibold">
                    <CallRoundedIcon/>
                    <a href="tel:+919597816034">+91 95978 16034</a>
                </div>
                <div className="social-media flex items-center gap-2 md:gap-4">
                    <a href=""><FacebookIcon/></a>
                    <a href=""><TwitterIcon/></a>
                    <a href=""><InstagramIcon/></a>
                    <a href=""><LinkedInIcon/></a>
                </div>
            </div>
        </div>
        {navExpand && 
                (<div className="fixed right-0 top-0 z-10 w-full h-full bg-gray-900 font-semibold p-4 overflow-y-scroll">
                    <ul class="text-white overflow-y-scroll">
                        <li className="p-2">
                            <span onClick={()=>{setNavExpand(false)}}><CloseIcon/></span>
                        </li>
                        <li className="p-2">
                            <Link to="/" onClick={()=>{setNavExpand(false)}}>Home</Link>
                        </li>
                        <li className="p-2">
                            <Link to="/company_overview" onClick={()=>{setNavExpand(false)}}>Company Overview</Link>
                        </li>
                        <li className="p-2">
                            <a href="#">Products</a>
                            <ul className='list-disc'>
                                <li className="p-1 text-sm">
                                    <a href="#">Evaporator</a>
                                    <ul>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/falling_rising_film_evaporator">Falling Film</Link>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/falling_rising_film_evaporator">Rising Film</Link>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/falling_forced_circulation">Forced Circulation</Link>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/falling_forced_circulation">Falling with Forced Combo</Link>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/mvr_evaporator">MVR Evaporator</Link>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <a href="#">Caustic Recovery Plant (CRP)</a>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <a href="#">Stripper Column</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className='list-disc'>
                                <li className="p-1 text-sm">
                                    <a href="#">Crystalizer</a>
                                    <ul>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/adiabatic_brine_chiller">Adiabatic Vaccum</Link>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/adiabatic_brine_chiller">Foreced Evaporative</Link>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/adiabatic_brine_chiller">Electrical Brine Chiller </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className='list-disc'>
                                <li className="p-1 text-sm">
                                    <a href="#">Dryers</a>
                                    <ul>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/agitated_flash_dryer">Agitated Thin Film Dryer</Link>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/agitated_flash_dryer">Paddle Dryer</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className='list-disc'>
                                <li className="p-1 text-sm">
                                    <a href="#">Sludge Handling System</a>
                                    <ul>
                                        <li className="p-1 text-sm font-medium">
                                            <a href="#">Sludge Dryer</a>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <a href="#">Multi Disc screw press</a>
                                        </li>
                                        <li className="p-1 text-sm font-medium">
                                            <Link to="/products/screw_press">Sludge Dewatering screw press</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="p-2">
                            <Link to="/project_gallery" onClick={()=>{setNavExpand(false)}}>Project Gallery</Link>
                        </li>
                        <li className="p-2">
                            <Link to="/contact_us" onClick={()=>{setNavExpand(false)}}>Contact Us</Link>
                        </li>
                    </ul>
                </div>)
                }
        <div className="logoNav p-4 md:px-10 xl:px-40 relative">
            <div className='flex justify-between md:justify-center items-center gap-4' onMouseEnter={()=>{setList(false)}}>
                <div className="flex items-center gap-2">
                    <img src={Logo} alt="" srcset="" className='w-10 h-10 md:w-20 md:h-20'/>
                    <div className="text-black text-center">
                        <div className='text-sm md:text-xl font-bold'>UKG EVAPORATORS & SERVICES</div>
                        <div className='text-xs md:text-sm'>(ISO 9001-2008 CERTIFIED COMPANY)</div>
                    </div>
                </div>
                <div className="menuBar md:hidden">
                    <div className="bar text-black" onClick={()=>{setNavExpand(true)}}><MenuIcon/></div>
                </div>
            </div>
        </div>
        <div className="relative lg:mx-20 xl:mx-40">
            <div className="navBar hidden md:block bg-blue-900 p-5 absolute w-full z-20 top-0 rounded text-white font-bold">
                <div className="flex items-center gap-10">
                    <Link to="/" onMouseEnter={()=>{setList(false)}}>Home</Link>
                    <Link to="/company_overview" onMouseEnter={()=>{setList(false)}}>Company Overview</Link>
                    <span onMouseEnter={()=>{setList(true)}}>Products {(!showList)?(<ArrowDropDownIcon/>):(<ArrowDropUpIcon className="text-gray-400"/>)}</span>
                    <Link to="/project_gallery" onMouseEnter={()=>{setList(false)}}>Project Gallery</Link>
                    <Link to="/contact_us" onMouseEnter={()=>{setList(false)}}>Contact Us</Link>
                </div>
            </div>
            {showList && (
                <div className="productList absolute top-10 z-10 bg-white w-full lg:w-3/4 rounded shadow-md p-2" onMouseLeave={()=>{setList(false)}}>
                    <div className="grid grid-cols-2 md:grid-cols-4 mt-10">
                        <div className="product">
                            <ul>
                                <li className="p-1 font-medium text-sm">
                                    <span className='uppercase font-medium text-sm text-blue-600'>Evaporator</span>
                                    <ul>
                                        <li className="p-1 font-medium text-sm uppercase">
                                            <Link to="/products/falling_rising_film_evaporator">Falling Film</Link>
                                        </li>
                                        <li className="p-1 font-medium text-sm uppercase">
                                            <Link to="/products/falling_rising_film_evaporator">Rising Film</Link>
                                        </li>
                                        <li className="p-1 font-medium text-sm uppercase">
                                            <Link to="/products/falling_forced_circulation">Forced Circulation</Link>
                                        </li>
                                        <li className="p-1 font-medium text-sm uppercase">
                                            <Link to="/products/falling_forced_circulation">Falling with Forced Combo</Link>
                                        </li>
                                        <li className="p-1 font-medium text-sm uppercase">
                                            <Link to="/products/mvr_evaporator">MVR Evaporator</Link>
                                        </li>
                                        <li className="p-1 font-medium text-sm uppercase">
                                            <a href="#">Caustic Recovery Plant (CRP)</a>
                                        </li>
                                        <li className="p-1 font-medium text-sm uppercase">
                                            <a href="#">Stripper Column</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="product">
                            <ul>
                                <li className="p-1 font-medium text-sm">
                                    <span className='uppercase font-medium text-blue-600'>Crystalizer</span>
                                    <ul>
                                        <li className="p-1 font-medium uppercase text-sm">
                                            <Link to="/products/adiabatic_brine_chiller">Adiabatic Vaccum</Link>
                                        </li>
                                        <li className="p-1 font-medium uppercase text-sm">
                                            <Link to="/products/adiabatic_brine_chiller">Foreced Evaporative</Link>
                                        </li>
                                        <li className="p-1 font-medium uppercase text-sm">
                                            <Link to="/products/adiabatic_brine_chiller">Electrical Brine Chiller </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="product">
                            <ul>
                                <li className="p-1 font-medium text-sm">
                                    <span className='uppercase font-medium text-blue-600'>Dryers</span>
                                    <ul>
                                        <li className="p-1 font-medium uppercase text-sm">
                                            <Link to="/products/agitated_flash_dryer">Agitated Thin Film Dryer</Link>
                                        </li>
                                        <li className="p-1 font-medium uppercase text-sm">
                                            <Link to="/products/agitated_flash_dryer">Paddle Dryer</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="product">
                            <ul>
                                <li className="p-1 font-medium text-sm">
                                    <span className='uppercase font-medium text-blue-600'>Sludge Handling System</span>
                                    <ul>
                                        <li className="p-1 font-medium uppercase text-sm">
                                            <a href="#">Sludge Dryer</a>
                                        </li>
                                        <li className="p-1 font-medium uppercase text-sm">
                                            <a href="#">Multi Disc screw press</a>
                                        </li>
                                        <li className="p-1 font-medium uppercase text-sm">
                                            <Link to="/products/screw_press">Sludge Dewatering screw press</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
           
        </div>
        {location.pathname === '/' && (
            <HomeSlider/>
        )}
    </div>
  )
}

export default Header
