import React from "react";
import '../assets/css/about.css'
import diamond from '../assets/diamond.png';
export const AboutUs = () => {
  return (
    <div className="bg-blue-800 p-4 py-10 md:px-6 xl:px-40" id="about-us">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 items-center justify-center gap-4">
        <div className="imageTile flex justify-center col-span-1">
            <img src={diamond} alt="Diamond Products" srcset="" className="w-3/4" />
        </div>
        <div className="aboutDetails text-left text-white col-span-2 mt-4 md:mt-0">
            <div className="text-2xl font-bold my-4">
                <span>UKG Evaporators & Services</span>
                <div className="text-right mt-2">
                    <span className="block w-24 h-0.5 bg-green-600"></span>
                    <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
                </div>
            </div>
            <div>
                <p className="m-2 my-4"><b>UKG EVAPORATORS & SERVICES</b> is one of the Major EPC Contractor in India in designing, manufacturing and exporting of MEE, ATFD, Multi Disc Screw Press, Sludge Dryer and HighlyEfficient Electric Chiller.</p>
                <p className="m-2 my-4">We offers and executes EPC Projects and our products and projects are of International quality and performance</p>
                <p className="m-2 my-4">Our design and Manufacturingfacilities are quite comprehensive and equipped with the most modernmachining facilities, the plant employs highly skilled & trained manpower.</p>
                <p className="m-2 my-4">The Products are manufactured using high quality raw materials andgoes through stringent quality checks before leaving the plant.</p>
            </div>
        </div>
      </div>
    </div>
  );
};
