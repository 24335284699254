import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fallingFilm from '../assets/falling_film.jpg';
import forcedCirculation from '../assets/falling_with_forced.jpg';
import mvr from '../assets/mvr.jpg';
import brineChiller from '../assets/brine_chiller.jpg';
import agitatedDryer from '../assets/agitated_film_dryer.jpg';
import screwPress from '../assets/screw_press.jpg';
import paddleDryer from '../assets/paddle_dryer.jpg';
import { Link } from 'react-router-dom';

export const Products = () => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none",justifyContent:"center",alignItems:"center",background: "gray",opacity:"0.7", borderRadius:'50%',width:"50px",height:"50px",zIndex:"9999" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none",justifyContent:"center",alignItems:"center",background: "gray",opacity:"0.7",borderRadius:'50%',width:"50px",height:"50px",zIndex:"9999" }}
        onClick={onClick}
      />
    );
  }
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
               autoplay: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
               autoplay: true,
              dots: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
               autoplay: true,
              dots: true
            }
          }
        ]
      };
    const fadeImages = [
        {
          url: fallingFilm,
          productName: 'Falling Film Evaporator, Rising Film Eavaporator',
          desc:'A falling film evaporator is a type of heat exchanger used to evaporate a liquid, typically in the process industries. It operates by distributing the liquid as a thin film down the surface of a heated tube or plate.',
          link:'/products/falling_rising_film_evaporator',
        },
        {
          url: forcedCirculation,
          productName: 'Forced Circulation, Falling with Forced circulation',
          desc:'Uses a pump to circulate the liquid through the heat exchanger tubes at high velocities.',
          link:'/products/falling_forced_circulation',
        },
        {
          url: mvr,
          productName: 'MVR Evaporator',
          desc:'Advanced and energy-efficient type of evaporator that recycles energy from the vapor generated during the evaporation process.',
          link:'/products/mvr_evaporator',
        },
        {
            url: brineChiller,
            productName: 'Adiabatic Evaporator, Forced Evaporative, Electrical Brine Chiller',
            desc:'Cooling system that uses the principle of adiabatic cooling, where air passes through a wetted medium and is cooled through the evaporation of water.',
            link:'/products/adiabatic_brine_chiller',
        },
        {
            url: agitatedDryer,
            productName: 'Agitated Dryer, Flash Dryer',
            desc:'Dryer that uses mechanical agitation to enhance the drying process.',
            link:'/products/agitated_flash_dryer',
        },
        {
          url: screwPress,
          productName: 'Sludge Dewatering Screw Press',
          desc:'Device used to reduce the water content of sludge by compressing it',
          link:'/products/screw_press',
      },
      ];
  return (
     <div className='p-4 md:px-6 xl:px-40' id="products">
        <div className="text-2xl font-bold">
            <span>Our Products</span>
            <div className="text-right mt-2">
                <span className="block w-24 h-0.5 bg-green-600"></span>
                <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
            </div>
        </div>
        <div className="slider-container gap-2 cursor-pointer mt-4">
            <Slider {...settings}>
                {fadeImages.map((fadeImage, index) => (
                    <div className="p-2" key={index}>
                        <div className="card shadow-lg border-gray-200 border rounded">
                            <div className="prodImg relative w-full h-80 bg-no-repeat bg-cover bg-center" style={{backgroundImage:`url(${fadeImage.url})`}}>
                                {/* <img src="" className='w-full object-cover' alt="" srcset="" /> */}
                                <h5 className="card-title text-lg font-medium absolute bottom-0 bg-blue-600 text-white text-center w-full p-2 bg-opacity-70 min-h-20 flex justify-center items-center">{fadeImage.productName}</h5>
                            </div>
                            <div className="card-body p-4">
                                <p className="card-text text-md truncate mb-2">{fadeImage.desc}</p>
                                <hr />
                                <Link to={fadeImage.link} className="block hover:bg-blue-800 hover:text-white text-blue-800 font-bold py-2 px-4 rounded mt-2">Learn More</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
        
     </div>
  )
}
