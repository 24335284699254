import React from 'react'
import prodImage from "../../assets/mvr.jpg";
export const MVR = () => {
  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div>
        <div className="text-2xl font-bold my-4 text-left">
            <span>MVR Evaporators</span>
            <div className="text-right flex flex-col items-start mt-2">
                <span className="block w-24 h-0.5 bg-green-600"></span>
                <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
            </div>
        </div>
            {/* <div className='font-bold mt-4 md:leading-relaxed text-gray-800'>
                MVR EVAPORATORS
            </div> */}
            <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
            Mechanical Vapour Compression machines based on the dynamic principle have rotating impeller blades and a diffuser which is located downstream from the impeller. Impeller blades run at high circumferential speeds and the vapour is supplied with energy. Acceleration of the vapour takes place followed by its de-acceleration which is done by the diffuser. This process helps convert high velocity into high pressure. The machine would be called an Centrifugal Turbo Compressor.
            <br />
            <br />
            The selection of compressor is determined by numerous parameters including operating conditions, the flow rate of the vapor to be compressed and the pressure rise needed.
            <br />
            <br />
            To heat an evaporation plant, a temperature difference on the heating surface is needed. Simply put, the temperature and pressure of the heating steam must be higher than the pressure and boiling temperature of the product in the system. This temperature difference is achieved through the compression of evaporated vapor from MVR Evaporator in a compressor.
            </div>
        </div>
        <div className='mt-4 text-center flex justify-center '>
            <img src={prodImage} alt='' className='w-3/4 border-2 shadow-md rounded'/>
        </div>
    </div>
</div>
  )
}
