import React from 'react'
import prodImage from "../../assets/agitated_film_dryer.jpg";
export const AgitatedDryer = () => {
  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div>
            <div className="text-2xl font-bold my-4 text-left">
                <span>Agitated Dryer, Flash Dryer</span>
                <div className="text-right flex flex-col items-start mt-2">
                    <span className="block w-24 h-0.5 bg-green-600"></span>
                    <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
                </div>
            </div>
                <div className='font-bold mt-4 md:leading-relaxed text-gray-800 uppercase'>
                Agitated Dryer
                </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                An agitated dryer is a type of industrial drying equipment used to remove moisture from materials through the application of heat and mechanical agitation. The key feature of an agitated dryer is the internal mechanism that stirs or agitates the material being dried, which helps to enhance heat transfer, break up lumps, and ensure uniform drying.
                </div>

                <div className='font-bold mt-6 md:leading-relaxed text-gray-800 uppercase'>
                    Flash Dryer
                </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                A flash dryer is a type of industrial drying equipment used to quickly remove moisture from various materials. It works by exposing the material to a high-velocity stream of hot air, causing rapid evaporation of moisture. The drying process in a flash dryer is almost instantaneous, which is why it is named "flash" dryer.
                </div>
            </div>
            <div className='mt-4 text-center flex justify-center '>
                <img src={prodImage} alt='' className='w-3/4 border-2 shadow-md rounded'/>
            </div>
        </div>
    </div>
  )
}
