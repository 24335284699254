import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import client1 from '../assets/client1.jpg';
import client2 from '../assets/client2.jpg';
import client3 from '../assets/client3.jpg';
import client4 from '../assets/client4.jpg';
import client5 from '../assets/client5.jpg';

export const Clients = () => {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none", background: "red" }}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none", background: "green" }}
            onClick={onClick}
          />
        );
      }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
               autoplay: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              initialSlide: 1,
               autoplay: true,
              dots: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
               autoplay: true,
              dots: false
            }
          }
        ]
      };
    const fadeImages = [
        {
          url:client1,
          clientName:'Client Name'
        },
        {
          url:client2,
          clientName:'Client Name'
        },
        {
          url:client3,
          clientName:'Client Name'
        },
        {
            url:client4,
            clientName:'Client Name'
        },
        {
            url:client5,
            clientName:'Client Name'
        },
      ];
  return (
     <div className='p-4 py-10 md:px-6 xl:px-40 bg-gray-100'>
        <div className="text-2xl font-bold my-4 text-left">
            <span>Our Prestigious Clients</span>
            <div className="text-right flex flex-col items-start mt-2">
                <span className="block w-24 h-0.5 bg-green-600"></span>
                <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
            </div>
        </div>
        <div className="slider-container gap-2 my-4 cursor-pointer">
            <Slider {...settings}>
                {fadeImages.map((fadeImage, index) => (
                    <div className="p-2" key={index}>
                        <div className="card shadow rounded bg-white text-black w-50 h-28 flex flex-col justify-center items-center p-2 text-center">
                            <div className="prodImg">
                              <img src={fadeImage.url} alt="" srcset="" />
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
        
     </div>
  )
}
