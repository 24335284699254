import React from 'react'
import fallingFilm from '../assets/falling_film.jpg';
import forcedCirculation from '../assets/falling_with_forced.jpg';
import mvr from '../assets/mvr.jpg';
import brineChiller from '../assets/brine_chiller.jpg';
import agitatedDryer from '../assets/agitated_film_dryer.jpg';

export const Gallery = () => {

    const fadeImages = [
        {
          url: fallingFilm,
          productName: 'Falling Film Evaporator, Rising Film Eavaporator',
          desc:'A falling film evaporator is a type of heat exchanger used to evaporate a liquid, typically in the process industries. It operates by distributing the liquid as a thin film down the surface of a heated tube or plate.'
        },
        {
          url: forcedCirculation,
          productName: 'Forced Circulation, Falling with Forced circulation',
          desc:'Uses a pump to circulate the liquid through the heat exchanger tubes at high velocities.'
        },
        {
          url: mvr,
          productName: 'MVR Evaporator',
          desc:'Advanced and energy-efficient type of evaporator that recycles energy from the vapor generated during the evaporation process.'
        },
        {
            url: brineChiller,
            productName: 'Adiabatic Evaporator, Forced Evaporative, Electrical Brine Chiller',
            desc:'Cooling system that uses the principle of adiabatic cooling, where air passes through a wetted medium and is cooled through the evaporation of water.'
        },
        {
            url: agitatedDryer,
            productName: 'Agitated Dryer, Flash Dryer',
            desc:'Dryer that uses mechanical agitation to enhance the drying process.'
        },
      ];

  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
        <div className="text-2xl font-bold my-4 text-left">
            <span>Project Gallery</span>
            <div className="text-right flex flex-col items-start mt-2">
                <span className="block w-24 h-0.5 bg-green-600"></span>
                <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
            </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 items-center">
            {fadeImages.map((fadeImage, index) => (
                <div className="p-2" key={index}>
                    <div className="card shadow-lg border-gray-200 border rounded">
                        <div className="prodImg relative w-full h-80 bg-no-repeat bg-cover bg-center" style={{backgroundImage:`url(${fadeImage.url})`}}>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}
