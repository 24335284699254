
import './App.css';
import { AboutUs } from './components/AboutUs';
import { Clients } from './components/Clients';
import { Footer } from './components/Footer';
import Header from "./components/Header";
import { IntroVideo } from './components/IntroVideo';
import { Products } from './components/Products';
import { Testimonials } from './components/Testimonials';


function App() {
  return (

    <div className="App">
        <AboutUs/>
        <IntroVideo/>
        <Products/>
        <Testimonials/>
        <Clients/>
    </div>
  );
}

export default App;
