import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Testimonials = () => {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none", background: "red" }}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none", background: "green" }}
            onClick={onClick}
          />
        );
      }
    var settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
               autoplay: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
               autoplay: true,
              dots: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
               autoplay: true,
              dots: false
            }
          }
        ]
      };
    const fadeImages = [
        {
          feedback: 'Their products are durable, efficient, and easy to maintain. The team was very professional and provided excellent after-sales support. Thanks to their innovative solutions, our energy consumption has reduced significantly, saving us both time and money.',
          clientName:'Vikram, CEO'
        },
        {
          feedback: 'Their products have exceeded our expectations in terms of performance and reliability. The technical support team is always available and quick to respond, ensuring minimal downtime for our operations. We highly recommend their services to anyone in the industry.',
          clientName:'Anjali, Plant Manager'
        },
        {
          feedback: 'The quality of their evaporators is top-notch, and their customer service is unparalleled. They went above and beyond to ensure that the installation was seamless and provided thorough training for our staff. We’ve seen a noticeable improvement in our operations since switching to their equipment',
          clientName:'Priya, Operations Director'
        },
        {
            feedback: 'We were impressed by the professionalism and expertise of this company from the very beginning. The installation process was smooth, and their team provided comprehensive training and support. We look forward to continuing our partnership with them.',
            clientName:'Rajasekar, General Manager'
        },
        {
            feedback: 'The team’s dedication to customer satisfaction is evident in every interaction we’ve had. Their equipment is robust and performs flawlessly even under demanding conditions.We look forward to continuing our partnership with them',
            clientName:'Velumani, General Manager'
        },
      ];
  return (
     <div className='bg-blue-800 p-4 py-10 md:px-6 xl:px-40 text-white mt-6'>
        <div className="text-2xl font-bold my-4 text-right">
            <span>Our Clients Feedback</span>
            <div className="text-right flex flex-col items-end mt-2">
                <span className="block w-24 h-0.5 bg-green-600"></span>
                <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
            </div>
        </div>
        <div className="slider-container gap-2 my-4 cursor-pointer">
            <Slider {...settings}>
                {fadeImages.map((fadeImage, index) => (
                    <div className="p-2" key={index}>
                        <div className="card shadow rounded bg-white text-black min-h-40 flex flex-col justify-center items-center p-4 text-center">
                            <p className="card-text mb-2 italic">"{fadeImage.feedback}"</p>
                            <div className="text-center text-green-800 font-semibold">- {fadeImage.clientName}</div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
        
     </div>
  )
}
