import React from 'react'
import poster from "../assets/Slider-1.jpg"
import ModeStandbyRoundedIcon from '@mui/icons-material/ModeStandbyRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import FilterTiltShiftRoundedIcon from '@mui/icons-material/FilterTiltShiftRounded';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';

export const CompanyOverview = () => {
  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 items-center">
            <div>
              <div className="text-2xl font-bold my-4 text-left">
                  <span>Company Overview</span>
                  <div className="text-right flex flex-col items-start mt-2">
                      <span className="block w-24 h-0.5 bg-green-600"></span>
                      <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
                  </div>
              </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                  Founded in 2021, UKG Evaporators & Services has rapidly emerged as a leading player in India’s Zero Liquid Discharge (ZLD) industry. UKG Evaporators & Services is a certified engineering firm dedicated to delivering high-quality environmental solutions. Our products adhere to international quality standards, and we pride ourselves on being a comprehensive provider of ZLD solutions for turnkey projects.
                </div>
                <div className="text-lg font-semibold mt-4 bg-blue-200 p-2 rounded">
                    <span><MyLocationRoundedIcon/> Our Mission</span>
                </div>
                <div className='font-medium mt-2 md:leading-relaxed text-gray-800'>
                  To deliver exceptional and cost-effective ZLD systems without compromising on quality.
                </div>
                <div className="text-lg font-semibold mt-4 bg-blue-200 p-2 rounded">
                    <span><VisibilityRoundedIcon/> Our Vision</span>
                </div>
                <div className='font-medium mt-2 md:leading-relaxed text-gray-800'>
                  To become the preferred ZLD solutions partner for clients worldwide.
                </div>
                <div className="text-lg font-semibold mt-4 bg-blue-200 p-2 rounded">
                    <span><FilterTiltShiftRoundedIcon/> Our Focus</span>
                </div>
                <div className='font-medium mt-2 md:leading-relaxed text-gray-800'>
                  <ul>
                    <li class="p-2">
                      <span><ModeStandbyRoundedIcon/> Minimize operating and maintenance expenses for our clients.</span>
                    </li>
                    <li class="p-2">
                      <span><ModeStandbyRoundedIcon/> Ensure maximum recovery during treatment and processing.</span>
                    </li>
                    <li class="p-2">
                      <span><ModeStandbyRoundedIcon/> Foster innovation in environmental solutions.</span>
                    </li>
                    <li class="p-2">
                      <span><ModeStandbyRoundedIcon/> Build enduring relationships based on trust, reliability, and confidence with our clients.</span>
                    </li>
                  </ul>
                </div>
            </div>
            <div className='mt-4'>
                <video src="" className='w-full' poster={poster}></video>
            </div>
        </div>
    </div>
  )
}
