import React from 'react'
import FallingFilm from '../../assets/falling_film.jpg';

export const FallingRising = () => {
  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div>
              <div className="text-2xl font-bold my-4 text-left">
                  <span>Falling Film & Rising Film Evaporators</span>
                  <div className="text-right flex flex-col items-start mt-2">
                      <span className="block w-24 h-0.5 bg-green-600"></span>
                      <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
                  </div>
              </div>
                <div className='font-bold mt-4 md:leading-relaxed text-gray-800'>
                    FALLING FILM EVAPORATOR
                </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                    A FALLING FILM EVAPORATOR is an industrial device to concentrate solutions, especially with heat sensitive components. The evaporator is a special type of heat exchanger.
                    <br />
                    <br />
                    Due to the intimate contact of the liquid with the heating surface, these evaporators are sensitive to fouling from precipitating solids; liquor velocity, typically low at liquor inlet (see above) is usually not sufficient to perform an effective self-cleaning of the tubes. Falling film evaporators are therefore used in clean, non-precipitating liquids. A typical application, in chemical industry, is for concentration of caustic soda.
                </div>

                <div className='font-bold mt-6 md:leading-relaxed text-gray-800'>
                    RISING FILM EVAPORATOR
                </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                    A RISING FILM or VERTICAL LONG TUBE EVAPORATOR is a type of evaporator that is essentially a vertical shell and tube heat exchanger. The liquid being evaporated is fed from the bottom into long tubes and heated with steam condensing on the outside of the tube from the shell side. This is to produces steam and vapor within the tube bringing the liquid inside to a boil. The vapor produced then presses the liquid against the walls of the tubes and causes the ascending force of this liquid.
                    <br />
                    <br />
                    As more vapor is formed, the centre of the tube will have a higher velocity which forces the remaining liquid against the tube wall forming a thin film which moves upwards. This phenomenon of the rising film gives the evaporator its name.
                </div>
            </div>
            <div className='mt-4 text-center flex justify-center '>
                <img src={FallingFilm} alt='' className='w-3/4 border-2 shadow-md rounded'/>
            </div>
        </div>
    </div>
  )
}
