import React from 'react'
import prodImage from "../../assets/screw_press.jpg"
export const ScrewPress = () => {
  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div>
            <div className="text-2xl font-bold my-4 text-left">
                <span>Sludge Dewatering Screw Press</span>
                <div className="text-right flex flex-col items-start mt-2">
                    <span className="block w-24 h-0.5 bg-green-600"></span>
                    <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
                </div>
            </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                A sludge dewatering screw press is a piece of industrial equipment used to reduce the water content in sludge, a semi-solid slurry typically resulting from wastewater treatment processes. The screw press dewaters the sludge by applying mechanical pressure, effectively separating the liquid (water) from the solid components.
                </div>
                <br />
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                    The core component is a rotating screw (or auger) inside a cylindrical screen or perforated drum. As the screw rotates, it pushes the sludge towards the discharge end, where pressure increases progressively.
                </div>
            </div>
            <div className='mt-4 text-center flex justify-center '>
                <img src={prodImage} alt='' className='w-3/4 border-2 shadow-md rounded'/>
            </div>
        </div>
    </div>
  )
}
