import React from 'react'
import prodImage from "../../assets/falling_with_forced.jpg"

export const ForcedCirculation = () => {
  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div>
            <div className="text-2xl font-bold my-4 text-left">
                <span>Forced Circulation & Falling with Forced circulation</span>
                <div className="text-right flex flex-col items-start mt-2">
                    <span className="block w-24 h-0.5 bg-green-600"></span>
                    <span className="block w-40 h-1.5 bg-green-600 mt-1"></span>
                </div>
            </div>
                <div className='font-bold mt-4 md:leading-relaxed text-gray-800'>
                    FORCED CIRCULATION EVAPORATORS
                </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                    Forced circulation evaporators play a critical role in sectors such as food and pharmaceutical industries which process highly sensitive materials. Forced circulation is especially useful when the water has to be removed from certain materials while retaining the core properties of the raw material. Forced circulation evaporators are also used to process liquids that have high viscosity and high solid content.
                </div>

                <div className='font-bold mt-6 md:leading-relaxed text-gray-800'>
                    FALLING WITH FORCED CIRCULATION
                </div>
                <div className='font-medium mt-4 md:leading-relaxed text-gray-800'>
                The combination of falling film followed by forced circulation evaporator system is the most economical solution to concentrate a fairly dilute solution to its saturation point. It achieves a better steam economy as compared to forced circulation system and is therefore the preferred system for solutions with lower TDS and hardness.
                <br />
                <br />
                In closed loop condition we can recover salt through crystallisation with this combo.
                </div>
            </div>
            <div className='mt-4 text-center flex justify-center '>
                <img src={prodImage} alt='' className='w-3/4 border-2 shadow-md rounded'/>
            </div>
        </div>
    </div>
  )
}
