import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import { Footer } from './components/Footer';
import { Testing } from './components/Testing';
import { ContactUs } from './components/ContactUs';
import { CompanyOverview } from './components/CompanyOverview';
import { Gallery } from './components/Gallery';
import { FallingRising } from './components/products/FallingRising';
import { ForcedCirculation } from './components/products/ForcedCirculation';
import { MVR } from './components/products/MVR';
import { AdiabiaticChiller } from './components/products/AdiabiaticChiller';
import { AgitatedDryer } from './components/products/AgitatedDryer';
import { ScrewPress } from './components/products/ScrewPress';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <Header/>
        <Routes>
            <Route path="/" element={<App />}/>
            <Route path="/contact_us" element={<ContactUs />}/>
            <Route path="/company_overview" element={<CompanyOverview />}/>
            <Route path="/project_gallery" element={<Gallery />}/>
            <Route path="/products/falling_rising_film_evaporator" element={<FallingRising />}/>
            <Route path="/products/falling_forced_circulation" element={<ForcedCirculation />}/>
            <Route path="/products/mvr_evaporator" element={<MVR />}/>
            <Route path="/products/adiabatic_brine_chiller" element={<AdiabiaticChiller />}/>
            <Route path="/products/agitated_flash_dryer" element={<AgitatedDryer />}/>
            <Route path="/products/screw_press" element={<ScrewPress />}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
