import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios';
import 'ldrs/ring';
import { ring2 } from 'ldrs'

export const ContactUs = () => {
  ring2.register()
  const [enqRes,setRes] = useState();
  const [enqMess,setMess] = useState();
  const [loader,setLoader] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
      } = useForm({
        criteriaMode: "all",
      })
      const onSubmit = (data) => {
        if(data.name==""){
          setError("name", {
            types: {
              required: "Provide valid Name",
            },
          })
          return;
        }
        if(data.email==""){
          setError("email", {
            types: {
              required: "Provide valid Email",
            },
          })
          return;
        }
        if(data.phoneno==""){
          setError("phoneno", {
            types: {
              required: "Provide valid Phone number",
            },
          })
          return;
        }

        setLoader(true);
        axios({
            url: "https://ukgevaporators.com/server/enquiry.php",
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: data,
        })
        .then((res) => {
            res = res.data;
            console.log(res);
            if(res.status){
              setRes(200);
            }else{
              setRes(500);
            }
            setMess(res.message);
            setLoader(false);
        })
        .catch((err) => {
            setRes(500);
            setMess("Something went wrong! Please try again");
            setLoader(false);
            console.error(err);
        });

      }

  return (
    <div className='p-4 py-10 md:px-6 xl:px-40 md:mt-20'>
        <div className="flex items-center justify-center ">
            <div className="contact-form w-full md:w-3/6 p-4 shadow-lg rounded">
                <div className="text-2xl font-semibold mb-4 text-center">
                Connect with Our Team
                </div>
                {(enqRes==200) && (
                  <div className='bg-green-200 text-green-800 font-semibold p-2 rounded text-center'>{enqMess}</div>
                )}
                {(enqRes==500) && (
                  <div className='bg-red-200 text-red-800 font-semibold p-2 rounded text-center'>{enqMess}</div>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="name m-2">
                        <input type="text" {...register("name")} className='mt-4 h-14 block w-full rounded-md border border-gray-300 shadow-sm p-2 focus-visible:outline-none focus:ring focus:ring-blue-700' placeholder='Your Name *'/>
                        {errors.name && errors.name.types && (
                          <p className='text-red-600 mt-1'>{errors.name.types.required}</p>
                        )}
                    </div>
                    <div className="email m-2">
                        <input type="email" {...register("email")} className='mt-4 h-14 block w-full rounded-md border border-gray-300 shadow-sm p-2 focus-visible:outline-none focus:ring focus:ring-blue-700' placeholder='Your Email ID *'/>
                        {errors.email && errors.email.types && (
                          <p className='text-red-600 mt-1'>{errors.email.types.required}</p>
                        )}
                    </div>
                    <div className="phoneno m-2">
                        <input type="number" {...register("phoneno")} className='mt-4 h-14 block w-full rounded-md border border-gray-300 shadow-sm p-2 focus-visible:outline-none focus:ring focus:ring-blue-700' placeholder='Mobile No *'/>
                        {errors.phoneno && errors.phoneno.types && (
                          <p className='text-red-600 mt-1'>{errors.phoneno.types.required}</p>
                        )}
                    </div>
                    <div className="message m-2">
                    <textarea {...register("message")} className='mt-4 h-36 block w-full rounded-md border border-gray-300 shadow-sm p-2 focus-visible:outline-none focus:ring focus:ring-blue-700' placeholder='Message'></textarea>
                    </div>
                    <div className="m-2">
                        <button type='submit' className='mt-4 p-3 px-4 bg-blue-800 text-white shadow font-medium rounded flex gap-10'>
                          <span>Send Message</span>
                          {loader && (<l-ring-2
                            size="25"
                            stroke="5"
                            stroke-length="0.25"
                            bg-opacity="0.1"
                            speed="0.8" 
                            color="white" 
                          ></l-ring-2>)}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}
