import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div className='bg-gray-800 p-4 py-10 md:px-6 xl:px-40 text-white'>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="contact-details">
                <div className="text-2xl text-white font-semibold text-left">Get In Touch</div>
                    <div className='mt-4 font-normal leading-relaxed'>S.F.NO: 361/2,3,4 & 9, URC Resorts Back Side, <br /> Therkku Thottam, SIPCOT Industrial Park, <br /> Kovai Main Road, Perundurai, <br /> Tamilnadu-638052.</div>
                    <div className='mt-4 font-medium leading-relaxed'>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <a href="mailto:nudhayakumar.ukges@gmail.com">nudhayakumar.ukges@gmail.com</a><br />
                        <a href="mailto:jayasankarganeshchk.ukges@gmail.com">jayasankarganeshchk.ukges@gmail.com</a><br />
                        <a href="mailto:info.ukges@gmail.com">info.ukges@gmail.com</a>
                    </div>
                    <div className='mt-4 font-medium leading-relaxed'>
                        <a href="tel:+919597816034">+91 95978 16034</a>, 
                        &nbsp;<a href="tel:+919944316090">+91 99443 16090</a>
                    </div>
            </div>
            <div className="quick-links">
                <div className="text-2xl text-white font-semibold text-left">Quick Links</div>
                <div className='mt-4 font-medium leading-relaxed'>
                    <ul>
                        <li className='p-2 hover:text-blue-400'>
                            <a href="#home">Home</a>
                            
                        </li>
                        <li className='p-2 hover:text-blue-400'>
                            <a href="#about-us">About Us</a>
                        </li>
                        <li className='p-2 hover:text-blue-400'>
                            <a href="#products">Our Products</a>
                        </li>
                        <li className='p-2 hover:text-blue-400'>
                            <Link to="/contact_us">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="products">
                <div className="text-2xl text-white font-semibold text-left">Products</div>
                <div className='mt-4 font-medium leading-relaxed'>
                    <ul>
                        <li className='p-2 hover:text-blue-400'>
                            <a href="#">Eavaporator</a>
                            
                        </li>
                        <li className='p-2 hover:text-blue-400'>
                            <a href="#">Crystalizer</a>
                        </li>
                        <li className='p-2 hover:text-blue-400'>
                            <a href="#">Dryers</a>
                        </li>
                        <li className='p-2 hover:text-blue-400'>
                            <a href="#">Sludge Handling System</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="map">
                <div className="mt-t">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5049.1229545889755!2d77.56634337600927!3d11.265606649978235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba96d001435d1ff%3A0xa421c2cb5bf94b05!2sUKG%20EVAPORATORS%20AND%20SERVICES!5e1!3m2!1sen!2sin!4v1722592141229!5m2!1sen!2sin" className='w-full h-60' style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
  )
}
